

import React, { useState, useMemo, useEffect } from "react";
import CustomTable from "../common/CustomTable"
import { Timesheetapi } from "../../middleware/interceptor"
import { Tabs, Form, Input, Select, DatePicker, message } from 'antd';
import dayjs from 'dayjs';
export const LoginInfo = () => {


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState()

  const [key, setKey] = useState("3")

  const [LoginDetails, setLoginDetails] = useState([])
  const [TempLoginDetails, setTempLoginDetails] = useState([])

  const [dates, setDates] = useState(dayjs().format('YYYY-MM-DD'));


  const columns = useMemo(() => [
    {
      accessorKey: 'sno',
      header: 'Sl.No',
      size: 80,
     
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      sortType: 'basic',
      sortingFn: (rowA, rowB) => {
        return rowA.index - rowB.index;
      },      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: 'Emp_img_url',
      header: 'Profile',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={row.original.Emp_img_url || 'default_profile_image.png'} // Use a placeholder if no image URL exists
            alt="Profile"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        </div>
      ),
    },
    {
      accessorKey: 'Jetz_id',
      header: 'Employee No',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'Emp_name',
      header: 'Employee Name',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: 'Role_id',
      header: 'Roles',
      size: 150,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },

    {
      accessorKey: 'Login_time',
      header: 'Login Time',
      size: 150, 
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ row }) => {
        const loginTime = row.original.Login_time;
    
        console.log(
          loginTime ? dayjs(loginTime).format('YYYY-MM-DD hh:mm A') : 'N/A',
          "Hello"
        );
    
        return (
          <span>
           {(loginTime && loginTime!='-') ? dayjs(loginTime).format('YYYY-MM-DD hh:mm A') : 'N/A'}
          </span>
        );
      },
    },
    {
      accessorKey: 'Logout_time',
      header: 'Logout Time',
      size: 150,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },

      Cell: ({ row }) => {
        const loginTime = row.original.Logout_time;
    
        console.log(loginTime,"Hello" );
    
        return (
          <span>
            {(loginTime && loginTime!='-') ? dayjs(loginTime).format('YYYY-MM-DD hh:mm A') : 'N/A'}
          </span>
        );
      },
    },
    
    
    

    {
      accessorKey: 'Location',
      header: 'Location',
      size: 80,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
  ], [LoginDetails]);


  const items = [
    {
      key: '1',
      label: 'Not Yet In',
      // children: (
      //     <div className="flex justify-center mt-[20px]">
      //         <div className="w-[98%] border-[#ddd] rounded-[4px] border bg-[#fff]">
      //             <div
      //                 className="px-5 py-3 bg-[#e6f4ff]"
      //                 style={{
      //                     borderBottom: '1px solid #ddd',
      //                     borderRadius: '4px 4px 0px 0px',
      //                 }}
      //             >
      //                 <h3 className="text-[16px]">Leave Pending</h3>
      //             </div>
      //             <div className="p-3" style={{ height: 'calc(100vh - 160px)', overflow: 'hidden auto' }}>


      //             </div>

      //             {/* </div> */}
      //         </div>
      //     </div>
      // ),
    },
    {
      key: '2',
      label: 'Late In',
      // children: (
      //     <div className="flex justify-center mt-[20px]">
      //         <div className="w-[98%] border-[#ddd] rounded-[4px] border bg-[#fff]">
      //             <div
      //                 className="px-5 py-3 bg-[#e6f4ff]"
      //                 style={{
      //                     borderBottom: '1px solid #ddd',
      //                     borderRadius: '4px 4px 0px 0px',
      //                 }}
      //             >
      //                 <h3 className="text-[16px]">Leave Pending</h3>
      //             </div>
      //             <div className="p-3" style={{ height: 'calc(100vh - 160px)', overflow: 'hidden auto' }}>


      //             </div>

      //             {/* </div> */}
      //         </div>
      //     </div>
      // ),
    },
    {
      key: '3',
      label: 'On Time',
      // children: (
      //     <div className="flex justify-center mt-[20px]">
      //         <div className="w-[98%] border-[#ddd] rounded-[4px] border bg-[#fff]">
      //             <div
      //                 className="px-5 py-3 bg-[#e6f4ff]"
      //                 style={{
      //                     borderBottom: '1px solid #ddd',
      //                     borderRadius: '4px 4px 0px 0px',
      //                 }}
      //             >
      //                 <h3 className="text-[16px]">Leave History</h3>
      //             </div>
      //             <div className="p-3" style={{ height: 'calc(100vh - 160px)', overflow: 'hidden auto' }}>
      //             </div>
      //         </div>
      //     </div>
      // ),
    },
    // {
    //   key: '4',
    //   label: 'On Leave',
    // },
  ];



  useEffect(() => {
    GetLoginDetails();
  }, [])


  const GetLoginDetails = async () => {


    await Timesheetapi.get(`api/projects/getloginentry?sdate=${dates}&edate=${dates}`).then((Responce) => {

      const LoggedInEmployees = Responce.data.LoggedInEmployees;
      const NotLoggedInEmployees = Responce.data.NotLoggedInEmployees;
      const OnTimeLogins = Responce.data.OnTimeLogins;
      const LateLogins = Responce.data.LateLogins;
      setLoginDetails(OnTimeLogins);
      setTempLoginDetails(Responce?.data);
    }).catch((err) => {

      console.log(err);
    })

  }


  const onChange = (key) => {    
  }






  useEffect(() => {


    console.log(key,LoginDetails,TempLoginDetails,"LoginDetails")
    if(Object.keys(TempLoginDetails).length > 0 ){

    if (key == 1) {
      setLoginDetails(TempLoginDetails.NotLoggedInEmployees);
    }
    else if (key == 2) {
      setLoginDetails(TempLoginDetails.LateLogins);
    }
    else if (key == 3) {
      setLoginDetails(TempLoginDetails.OnTimeLogins);
    }
    else {
      setLoginDetails([]);
    }

  }

  },[key,LoginDetails]) 

  const handleDateChange = async (date, dateString) => {

    if(date){

      console.log(date, dateString,key, "Selected Date");

    const formattedEndDate = dayjs(date).format('YYYY-MM-DD');

    setDates(formattedEndDate);
    await Timesheetapi.get(`api/projects/getloginentry?sdate=${formattedEndDate}&edate=${formattedEndDate}`).then((Responce) => {

      const LoggedInEmployees = Responce.data.LoggedInEmployees;
      const NotLoggedInEmployees = Responce.data.NotLoggedInEmployees;
      const OnTimeLogins = Responce.data.OnTimeLogins;
      const LateLogins = Responce.data.LateLogins;
      setLoginDetails(OnTimeLogins);
      setTempLoginDetails(Responce?.data);

      setKey(key);

    }).catch((err) => {

      console.log(err);
    })
    }
    

  };


  console.log(dates,"Dates")

  return (

    <>
<Tabs className='custom_tab' defaultActiveKey="3" items={items} onChange={(key) => setKey(key)} />


      

      <div className="mt-[20px] overflow-auto custom-table"> 


    <div className="">
      <DatePicker className="h-10 z-[99] top-[113px] absolute left-[20px]" 
      format="DD-MM-YYYY" 
      values={dayjs(dates)}
       onChange={handleDateChange} 
       defaultValue={dayjs(dates)} 
       allowClear={false}
       />

      </div>


        {
          key && (     
             <>
          <CustomTable
          columns={columns}
          data={LoginDetails}
          pinColumn={['action']}
        />
        </>
          )
        }
        
      </div>
    </>
  )

}