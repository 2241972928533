import axios from "axios";
import { LocalStorageService } from "../../src/components/service/LocalStorageService";


export const Timesheetapi = axios.create({


    ///////////////////////  FOBESOFT LIVE     ////////////////////////////
  
   baseURL:'https://myapi.jetzerp.com/'

 //baseURL : 'https://localhost:44385/'
  

  
  });

export const setAxiosDefauls = () => {

    Timesheetapi.interceptors.request.use(
        onRequestFulfilled
        , onRequestRejected);

    Timesheetapi.interceptors.response.use(
        onResponseFulfilled
        , onResponseRejected);

    Timesheetapi.interceptors.request.use(
        onRequestFulfilled
        , onRequestRejected);

    Timesheetapi.interceptors.response.use(
        onResponseFulfilled
        , onResponseRejected);
}

export const resetSession = () => {
    localStorage.remove("bearer-token");
    //history.push('/');
}

const onRequestFulfilled = (config) => {
    const localStorageService = LocalStorageService.getService();
    const token = localStorageService.getAccessToken("bearer-token");
    if (token) {
        config.headers = {
            "Authorization": "Bearer " + token,
            "Access-Control-Allow-Origin": "*"
        };



        
    }
    else {
        config.headers = {
           
            //   "Cache-Control": "no-cache"
            //  ,"Access-Control-Allow-Origin": "*"
            //  ,"Content-Type":"application/json"
            //  , "Pragma": "no-cache"
            //  , "X-Frame-Options": "SAMEORIGIN",
        };
    }
    return Promise.resolve(config);
}

const onRequestRejected = (error) => {
    return Promise.reject(error);
}

const onResponseFulfilled = (config) => {
    return Promise.resolve(config);
}

const onResponseRejected = (error) => {
    return Promise.reject(error);
}