import { React, useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { CiViewTimeline } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { FaCalendarAlt, FaCreditCard, FaUserFriends, FaUserTie } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { BiSpreadsheet } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";
import { IoDocumentsSharp } from "react-icons/io5";
import { LuUserCog2 } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";
import { Menu } from 'antd';
import { FaBars } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { SiGooglesheets } from "react-icons/si";
import { RiFileUserLine } from "react-icons/ri";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { BsClipboard2Pulse } from "react-icons/bs";
import { MdCoPresent } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa6";
import { FaTicketAlt } from "react-icons/fa";
import Icon, {LoginOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; 

export const Sidebar = ({ Emp_role }) => {

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [activeKey, setActiveKey] = useState('');


  const navigate = useNavigate(); 

  const onOpenChange = (openKeys,key) => {

    console.log(openKeys,key,'open')
    setActiveKey('35')

  }





  useEffect(() => { 
    const path = window.location.pathname;

    if (path.includes('/Logins/LoginInfo')) {
      setActiveKey('35');
    }
    else if(path.includes('/timesheets')){
      setActiveKey('1');
    }
    else if (path.includes('/Leave/LeaveApply')){
      setActiveKey('26');
    }
    else if (path.includes('/Leave/LeaveCancel')){
      setActiveKey('27');
    }
    else if (path.includes('/Leave/Review')){
      setActiveKey('28');
    }
    else if (path.includes('/ticket')){
      setActiveKey('31');
    }
    else if (path.includes('/Teamtimesheet')){
      setActiveKey('2');
    }
    else if (path.includes('/employee')){
      setActiveKey('4');
    }
    else if (path.includes('/Clients')){
      setActiveKey('5');
    }
    else if (path.includes('/projects')){
      setActiveKey('6');
    }
    else if (path.includes('/employeerate')){
      setActiveKey('7');
    }
    else if (path.includes('/client-allocation')){
      setActiveKey('8');
    }
    else if (path.includes('/report')){
      setActiveKey('9');
    }
    else if (path.includes('/Employee_report')){
      setActiveKey('15');
    }
    else if (path.includes('/Invoice_report')){
      setActiveKey('16');
    }
    //

  },[activeKey])


  console.log("Roles_id", Emp_role)
  return (
    <aside className="max-w-[225px] bg-[#fff] text-white flex flex-col items-center py-10" style={{ borderRight: '1px solid #cfcfcf' }}>
      <div className='flex justify-between w-[100%] px-3 gap-4'>
        <button className='flex items-center justify-center rounded-[5px] h-[30px] w-[30px] bg-[#fff] border border-[#3b82f6]' onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
          {isDrawerOpen ? (
            <IoClose className='text-[20px] text-[#3b82f6] ' />

          ) : (
            <FaBars className='text-[18px] text-[#3b82f6]' />
          )}
        </button>
          <img className={`side-logo1 ${isDrawerOpen ? '' : 'hidden'}`} src='/jetz-logo.png' alt="logo" />
          <img className={`side-logo2 ${isDrawerOpen ? 'hidden' : ''}`} src='/logo-jetz.png' alt="logo" />

      </div>
      {isDrawerOpen ? (
        <ul className="w-full mt-[1.5rem]">


          <Menu mode="inline" theme="light"   selectedKeys={[activeKey]}   defaultSelectedKeys={localStorage.getItem('emp_role_id') == 2 ? ['1'] : localStorage.getItem('emp_role_id') == 3 ? ['4'] : localStorage.getItem('emp_role_id') == 5 ? ['31']: ['1']} 
                onClick={({ key }) => setActiveKey(key)} // Update active key on click
>
            {(localStorage.getItem('emp_role_id'))  && (
              <>

              {
                ((localStorage.getItem('emp_role_id') != 3 ) && localStorage.getItem('emp_role_id') != 5 )&&(
              
                <Menu.Item key="1" icon={<BiSpreadsheet style={{ fontSize: '18px' }} />}>
                <NavLink to="/timesheets">
                  My Timesheet
                </NavLink>
              </Menu.Item>
              )}
              
              {localStorage.getItem('emp_role_id') == 5 && (
              <Menu.Item key="31" icon={<FaTicketAlt style={{ fontSize: '18px' }} />}>
                <NavLink to="/ticket">
                  Tickets
                </NavLink>
              </Menu.Item>
              )}


              

              {/* {localStorage.getItem('emp_role_id') !=3 && (
              <Menu.SubMenu
                key="sub2"
               icon={<FaCalendarAlt style={{ fontSize: '15px' }} />}
                title={<span className="menu-title">Leave</span>}
              >

              <Menu.Item key="26" icon={<FaCalendarCheck style={{ fontSize: '15px' }} />}>
               <NavLink to="/Leave/LeaveApply">
                 Leave Apply
               </NavLink>
             </Menu.Item>
                <Menu.Item key="27" icon={<TbReportAnalytics style={{fontSize:'19px'}} />}>
                  <NavLink to="/Leave/LeaveCancel">
                    Leave Cancel
                  </NavLink>
                </Menu.Item>
                {localStorage.getItem('emp_role_id') ==2 && (
                  <>
                  <Menu.Item key="28" icon={<TbReportAnalytics style={{fontSize:'19px'}} />}>
                  <NavLink to="/Leave/Review">
                       Review 
                  </NavLink>
                </Menu.Item>
                </>)


                }
            
                </Menu.SubMenu>

                
            )} */}
             </>
            )}

            {localStorage.getItem('emp_role_id') == 2 && (
              <Menu.Item key="2" icon={<SiGooglesheets style={{ fontSize: '16px' }} />}>
                <NavLink to="/Teamtimesheet">
                  Team Timesheets
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="4" icon={<FaUserFriends style={{ fontSize: '18px' }} />}>
                <NavLink to="/employee">
                  Employee
                </NavLink>
              </Menu.Item>
            )}
                 {(localStorage.getItem('emp_role_id') ==4 || localStorage.getItem('emp_role_id') ==3 ) && (
              <Menu.SubMenu
                key="sub3"
               icon={<MdCoPresent style={{ fontSize: '16px' }} />}
                title={<span className="menu-title">Logins</span>}
              >
{/* 
              <Menu.Item key="25" icon={<PersonAddAlt1Icon style={{ fontSize: '18px' }} />}>
               <NavLink to="/Attendance/AttendanceInfo">
               AttendanceInfo
               </NavLink>
             </Menu.Item>
             {(localStorage.getItem('emp_role_id') ==4 || localStorage.getItem('emp_role_id') ==2)&& (
             <Menu.Item key="30" icon={<BsClipboard2Pulse style={{ fontSize: '15px' }} />}>
               <NavLink to="/Attendance/AttendanceReview">
               AttendanceReview
               </NavLink>
             </Menu.Item>
             )} */} 

            {(localStorage.getItem('emp_role_id') ==4 || localStorage.getItem('emp_role_id') ==3 )&& (   

             <Menu.Item key="35" icon={<LoginOutlined style={{ fontSize: '18px' }} />}>
               <NavLink to="/Logins/LoginInfo">
               Login Info
               </NavLink>
             </Menu.Item>
      )}

                </Menu.SubMenu>

                
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="5" icon={<FaUserTie style={{fontSize:'17px'}} />}>
                <NavLink to="/Clients">
                  Clients
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="6" icon={<FaFileAlt className="text-[16px]" />}>
                <NavLink to="/projects">
                  Projects
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="7" icon={<FaCreditCard className="text-[16px]" />}>
                <NavLink to="/employeerate">
                  Rate Card
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.SubMenu
                key="sub1"
                icon={<IoDocumentsSharp style={{fontSize:'18px'}} />}
                title={<span className="menu-title">Reports</span>}
              >
                <Menu.Item key="8" icon={<LuUserCog2 style={{fontSize:'19px'}} />}>
                  <NavLink to="/client-allocation">
                    Client Allocation
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="9" icon={<TbReportAnalytics style={{fontSize:'19px'}} />}>
                  <NavLink to="/report">
                    Report
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="15" icon={<RiFileUserLine style={{fontSize:'19px'}} />}>
                  <NavLink
                    to="/Employee_report"
                    activeClassName="active-link"
                  >
                    Employee Report
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="16" icon={<LiaFileInvoiceSolid style={{fontSize:'19px'}} />}>
                  <NavLink
                    to="/Invoice_report"
                    activeClassName="active-link"
                  >
                    Invoice Generate
                  </NavLink>
                </Menu.Item>

                <Menu.Item key="17" icon={<LiaFileInvoiceSolid style={{fontSize:'19px'}} />}>
                  <NavLink
                    to="/SiteDetails"
                    activeClassName="active-link"
                  >
                    Site Details
                  </NavLink>
                </Menu.Item>

              </Menu.SubMenu>
            )}
          </Menu>
        </ul>
      ) : (
        <ul className="w-full mt-[1.5rem]">
          <Menu className='custom-menu' mode="inline" theme="light" defaultSelectedKeys={localStorage.getItem('emp_role_id') == 2 ? ['1'] : localStorage.getItem('emp_role_id') == 3 ? ['4'] : ['1']} onOpenChange={onOpenChange}>
            {localStorage.getItem('emp_role_id') != 3 && (
              <>

{(localStorage.getItem('emp_role_id') != 3 && localStorage.getItem('emp_role_id') != 5) && (
  <Menu.Item key="1">
  <NavLink to="/timesheets">
    <BiSpreadsheet style={{ fontSize: '18px' }} />
  </NavLink>
</Menu.Item>
)}
              
              {(localStorage.getItem('emp_role_id') == 5) && (
                <Menu.Item key="31">
                <NavLink to="/ticket">
                  <FaTicketAlt style={{ fontSize: '18px' }} />
                </NavLink>
              </Menu.Item> 
          )}
              
              
             </>
              
            )}
               




           {/* {localStorage.getItem('emp_role_id') != 3 && (
              <Menu.SubMenu
                key="sub2"
                icon={<FaCalendarAlt style={{fontSize:'15px',position:'relative',left:'13px'}} />}                // title={<span className="menu-title">Reports</span>}
              >
                <Menu.Item key="8">
                  <NavLink to="/Leave/LeaveApply">
                  <FaCalendarCheck className="text-[17px]" />
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="15">
                  <NavLink
                    to="/Leave/LeaveCancel"
                    activeClassName="active-link"
                  ><RiFileUserLine style={{fontSize:'19px'}} />
                  </NavLink>
                </Menu.Item>


             

                {localStorage.getItem('emp_role_id') ==2 && (
                  <>
                 <Menu.Item key="19">
                  <NavLink
                    to="/Leave/Review"
                    activeClassName="active-link"
                  ><RiFileUserLine style={{fontSize:'19px'}} />
                  </NavLink>
                </Menu.Item>
                </>)
                   }



              </Menu.SubMenu>
            )} */}

            {localStorage.getItem('emp_role_id') == 2 && (
              <Menu.Item key="2">
                <NavLink to="/Teamtimesheet">
                  <SiGooglesheets style={{ fontSize: '16px' }} />
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="4">
                <NavLink to="/employee">
                  <FaUserFriends style={{ fontSize: '18px' }} />
                </NavLink>
              </Menu.Item>
            )}
            {(localStorage.getItem('emp_role_id') ==4 || localStorage.getItem('emp_role_id') ==3)&& (
              <Menu.SubMenu
                key="sub3"
                icon={<MdCoPresent style={{fontSize:'18px',position:'relative',left:'13px'}} />}                // title={<span className="menu-title">Reports</span>}
              >
                {/* <Menu.Item key="11">
                  <NavLink to="/Attendance/AttendanceInfo">
                  <PersonAddAlt1Icon style={{fontSize:'20px'}} />
                  </NavLink>
                </Menu.Item>


                {(localStorage.getItem('emp_role_id') ==4 || localStorage.getItem('emp_role_id') ==2)&& (
                <Menu.Item key="12">
                  <NavLink to="/Attendance/AttendanceReview">
                  <BsClipboard2Pulse className="text-[18px]" />
                  </NavLink>
                </Menu.Item>
                )} */}

              {(localStorage.getItem('emp_role_id') ==4 || localStorage.getItem('emp_role_id') ==3)&& (
                <Menu.Item key="52">
                  <NavLink to="/Logins/LoginInfo">
                  <LoginOutlined className="text-[18px]" />
                  </NavLink>
                </Menu.Item>
              )}
  
              </Menu.SubMenu>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="5" >
                <NavLink to="/Clients">
                  <FaUserTie className="text-[17px]" />
                </NavLink>
              </Menu.Item>
            )}

            {(localStorage.getItem('emp_role_id') == 3 || localStorage.getItem('emp_role_id') == 2) && (
              <Menu.Item key="6" >
                <NavLink to="/projects">
                  <FaFileAlt className="text-[16px]" />
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.Item key="7" >
                <NavLink to="/employeerate">
                  <FaCreditCard className="text-[16px]" />
                </NavLink>
              </Menu.Item>
            )}

            {localStorage.getItem('emp_role_id') == 3 && (
              <Menu.SubMenu
                key="sub1"
                icon={<IoDocumentsSharp style={{fontSize:'20px',position:'relative',left:'13px'}} />}
                // title={<span className="menu-title">Reports</span>}
              >
                <Menu.Item key="8">
                  <NavLink to="/client-allocation">
                  <LuUserCog2 className="text-[19px]" />
                  </NavLink>
                </Menu.Item>


                <Menu.Item key="9">
                  <NavLink to="/report">
                  <TbReportAnalytics className="text-[19px]" />
                  </NavLink>
                </Menu.Item>



                <Menu.Item key="15">
                  <NavLink
                    to="/Employee_report"
                    activeClassName="active-link"
                  ><RiFileUserLine style={{fontSize:'19px'}} />
                  </NavLink>
                </Menu.Item>



                <Menu.Item key="16">
                  <NavLink
                    to="/Invoice_report"
                    activeClassName="active-link"
                  >
                    <LiaFileInvoiceSolid style={{fontSize:'19px'}} />
                  </NavLink>
                </Menu.Item>

              </Menu.SubMenu>
            )}
          </Menu>
        </ul>
      )
      }
    </aside >
  );
};
